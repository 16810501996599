<template>
  <div>
    <div
      v-if="type == 'basic' || type == 'community'"
      class="w-60 h-72 border bg-gray-light rounded-2xl relative flex items-center"
      :class="{
        'w-[184px] h-52': formatSm,
        'border-secondary border-4': enableSelection && selected,
        'border-gray-200 border-4': enableSelection && !selected,
      }"
    >
      <div
        v-if="badgeImage"
        class="w-full h-full rounded-xl flex justify-center items-center overflow-hidden"
      >
        <img
          :src="badgeImage"
          alt="badgePicture"
          class="object-cover min-h-full min-w-full"
        />
      </div>
      <div
        class="absolute bottom-0 w-full p-2"
        :class="!formatSm ? 'h-20' : 'h-16'"
      >
        <div
          class="backdrop-blur-sm h-full w-full bg-black bg-opacity-40 rounded-xl flex items-center p-4"
        >
          <div>
            <p
              class="text-white font-normal text-left leading-tight"
              :class="!formatSm ? 'text-xs' : 'text-xxs'"
            >
              {{ issuerName }}
            </p>
            <p
              class="text-white font-medium text-left leading-tight line-clamp-1 break-all"
              :class="!formatSm ? 'text-lg' : ''"
            >
              {{ badgeName }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="type == 'participation'"
      class="w-60 h-72 border bg-white bg-[url('/assets/images/attendanceProofPreviewTopBanner.png')] bg-contain bg-no-repeat rounded-2xl relative flex flex-col"
      :class="{
        'w-[184px] h-52': formatSm,
        'border-secondary border-4': enableSelection && selected,
        'border-gray-200 border-4': enableSelection && !selected,
      }"
    >
      <div class="flex justify-center mt-4 mb-3">
        <img
          v-if="badgeImage"
          :src="badgeImage"
          alt="badgePicture"
          class="w-32 h-32 rounded-full border-2"
        />
        <div
          v-else
          class="w-32 h-32 shadow-inner bg-gray-light rounded-full border-2"
        ></div>
      </div>
      <p
        class="text-center leading-5 line-clamp-1 font-normal break-all"
        :class="!formatSm ? '' : 'text-xs'"
      >
        {{ issuerName }}
      </p>
      <p
        class="text-center line-clamp-1 font-bold break-all"
        :class="!formatSm ? '' : 'text-sm'"
      >
        {{ badgeName }}
      </p>
      <div class="grow"></div>
      <div v-if="!formatSm" class="flex p-3">
        <div>
          <p class="bg-gray-light text-xs px-1 py-0.5 rounded-md">
            {{ badgeStartDate ? badgeStartDate.split('T')[0] : '.. /.. /..' }}
          </p>
          <p
            v-if="
              (badgeStartDate &&
                badgeEndDate &&
                badgeStartDate.split('T')[0] != badgeEndDate.split('T')[0]) ||
              !badgeStartDate ||
              !badgeEndDate
            "
            class="bg-gray-light text-xs px-1 py-0.5 rounded-md"
          >
            {{ badgeEndDate ? badgeEndDate.split('T')[0] : '.. /.. /..' }}
          </p>
        </div>
        <div class="grow"></div>
        <div class="flex items-end">
          <span class="text-xs bg-gray-light px-1 py-0.5 rounded-md">{{
            badgeLocation ? badgeLocation : '...'
          }}</span>
        </div>
      </div>
    </div>
    <div
      v-if="type == 'membership'"
      class="w-60 rounded-xl relative flex items-center"
      :class="{
        'border-secondary border-4': enableSelection && selected,
        'border-gray-200 border-4': enableSelection && !selected,
        '!w-96 !rounded-2xl': formatXl,
      }"
    >
      <div
        v-if="badgeImage"
        class="w-[240px] h-[153px] rounded-lg flex justify-center items-center overflow-hidden"
        :class="{
          '!w-96 !h-[245px]': formatXl,
          '!rounded-2xl': formatXl,
        }"
      >
        <img
          :src="badgeImage"
          alt="badgePicture"
          class="object-cover min-h-full min-w-full"
        />
      </div>
      <div
        v-else
        class="w-[240px] h-[153px] bg-gray-light rounded-lg"
        :class="{
          '!w-96 !h-[245px]': formatXl,
          '!rounded-2xl': formatXl,
        }"
      ></div>
      <div
        class="absolute h-10 left-0 top-0 p-2"
        :class="{
          '!h-12': formatXl,
        }"
      >
        <div
          class="backdrop-blur-sm h-full inline-flex bg-black bg-opacity-40 rounded-md items-center p-2"
          :class="{
            '!rounded-xl px-3 py-5': formatXl,
          }"
        >
          <img
            :src="require('/assets/images/mydid-logo-white.png')"
            alt="logo"
            class="w-12"
            :class="{
              '!w-20': formatXl,
            }"
          />
        </div>
      </div>
      <div
        class="absolute h-14 bottom-0 w-full p-2"
        :class="{
          '!h-24': formatXl,
        }"
      >
        <div
          class="backdrop-blur-sm h-full w-full bg-black bg-opacity-40 rounded-md flex items-center px-2"
          :class="{
            '!rounded-2xl !px-4 py-5': formatXl,
          }"
        >
          <div>
            <p
              class="text-white text-xxxs font-light text-left mb-0.5"
              :class="{
                '!text-sm': formatXl,
              }"
            >
              2023
            </p>
            <p
              class="text-white text-xxs font-medium text-left line-clamp-1 break-all"
              :class="{
                '!text-xl': formatXl,
              }"
            >
              Membership card
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    badgeImage: String,
    badgeName: String,
    badgeStartDate: String,
    badgeEndDate: String,
    badgeLocation: String,
    issuerName: String,
    type: String,
    formatSm: Boolean,
    formatXl: Boolean,
    enableSelection: Boolean,
    selected: Boolean,
  },
};
</script>
