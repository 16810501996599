const qrcode = require('qrcode');
const GIFEncoder = require('gifencoder');
const { UR, UREncoder } = require('@ngraveio/bc-ur');

module.exports.createGif = async function (message) {
  return new Promise(async (resolve, reject) => {
    const messageBuffer = Buffer.from(message);
    const ur = UR.fromBuffer(messageBuffer);
    const maxFragmentLength = 300;
    const firstSeqNum = 0;
    const urEncoder = new UREncoder(ur, maxFragmentLength, firstSeqNum);
    const messageParts = urEncoder.encodeWhole();

    const encoder = new GIFEncoder(500, 500);
    // stream the results as they are available into myanimated.gif
    const stream = encoder.createReadStream();
    let chunks = [];
    stream.on('data', (chunk) => {
      chunks = [...chunks, ...chunk];
    });
    stream.on('end', () => {
      resolve(
        'data:image/gif;base64,' + Buffer.from(chunks).toString('base64')
      );
    });

    encoder.start();
    encoder.setRepeat(0); // 0 for repeat, -1 for no-repeat
    encoder.setDelay(100); // frame delay in ms
    encoder.setQuality(100000); // image quality. 10 is default.

    const promiseArray = [];

    for (var part of messageParts) {
      promiseArray.push(
        new Promise(async (resolve) => {
          let url = await qrcode.toDataURL(part, {
            width: 500,
            errorCorrectionLevel: 'L',
            margin: 8,
          });
          const canvas = document.createElement('canvas');
          canvas.width = 500;
          canvas.height = 500;
          const ctx = canvas.getContext('2d');
          let img = new Image();
          img.onload = function () {
            ctx.drawImage(img, 0, 0);
            encoder.addFrame(ctx);
            resolve();
          };
          img.src = url;
        })
      );
    }

    await Promise.all(promiseArray);

    encoder.finish();
  });
};
