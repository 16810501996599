<template>
  <div class="mt-10">
    <div v-if="!vpDetails && !loading">
      <p class="text-primary text-lg text-center mb-1">
        Welcome to Open Badge Checker !
      </p>
      <p class="text-secondary text-center mb-3">
        Please put valid queries in URL to scan an Open Badge.
      </p>
    </div>
    <div v-else-if="!vpDetails && loading">
      <div class="flex justify-center mb-5">
        <custom-loader class="" color="#D0C4E7" size="50px"></custom-loader>
      </div>
      <p class="text-secondary text-center mb-3">Checking information</p>
    </div>
    <div
      v-else-if="vpDetails && vpDetails.verifiableCredentials"
      class="grid grid-cols-1 2xl:gap-5 w-full 2xl:w-1/2 mx-auto 2xl:mb-7"
    >
      <div v-for="vc in vpDetails.verifiableCredentials" class="2xl:mb-7">
        <vc-presentation :vc="vc" :lang="lang"></vc-presentation>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject } from 'vue';
import { useRoute } from 'vue-router';
export default {
  setup() {
    const route = useRoute();
    const api = inject('api');

    const vpDetails = ref(null);
    const lang = ref('en');
    const loading = ref(false);

    try {
      if (route.query.code) {
        loading.value = true;
        api
          .getVPDetailsFromCode(route.query.code)
          .then((response) => {
            loading.value = false;
            vpDetails.value = response.data;
          })
          .catch((e) => {
            loading.value = false;
            console.log(e);
          });
      }

      if (route.query.lang) lang.value = route.query.lang;
    } catch (e) {
      console.log(e);
    }

    return { vpDetails, lang, loading };
  },
};
</script>
