import { createRouter, createWebHistory } from 'vue-router';
import Home from './views/Home';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', component: Home },
    { path: '/:notFound(.*)', redirect: '/' },
  ],
});

/** FOR TESTING QUERIES */

// // // BSC badge on-chain
// // const q = encodeURI(
// //   '031841486ffdccd7b9266f95b78c77e92e693ae158d73cfb2c137003a808b282'
// // );
// // const s = null;
// // const v = null;

// // BSC badge off-chain
// import openbadgeExample from '../assets/openbadge-example';
// const { signature, ...content } = openbadgeExample;
// const q = encodeURI(JSON.stringify(content));
// const s = encodeURI(JSON.stringify(signature));
// const v = encodeURI('adutres@mytip.co');

// router.beforeEach((to, from, next) => {
//   if (!to.query.q) {
//     const query = { q };
//     if (s) query.s = s;
//     if (v) query.v = v;
//     return next({
//       path: to.path,
//       query,
//     });
//   }
//   return next();
// });
/** END TESTING QUERIES */

export default router;
