<template>
  <div
    class="flex items-center w-fit border border-secondary bg-white gap-1 py-1 pl-2 pr-3 rounded-full"
  >
    <div
      class="w-4 h-4 inline-flex rounded-full"
      :style="`background-color:${color}`"
    ></div>
    <img :src="emoji" class="w-5 mr-0.5" />
    <p class="text-sm font-medium">{{ name }}</p>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    emoji: String,
    color: String,
  },
  setup() {},
};
</script>
